<template>
  <v-dialog hide-overlay content-class="notice_dialog" v-model="opened" :width="500">
    <v-card>
      <v-card-title class="noticePopupWrapper">
        <div class="popup_admin">
          <div class="popup_header">
            <div class="popup_header_item1">NOTIFICATION</div>          
          </div>
          <div class="popup_close">
            <a v-ripple class="btn-close3" title="Cancel & Close" @click="onClose">
              <i class="mdi mdi-close"></i>
            </a>
          </div>
          <div class="popup_contents_wrap">
            <div class="popup_contents">
              <div class="popup_contents_box">
                <!-- <fieldset>
                  <legend>Notice </legend>
                  <div class="notification_content">
                    <span class="notice_value">{{notification}}</span>
                  </div>
                </fieldset> -->
                <fieldset>
                  <legend>New Open Service  </legend>            
                  <v-list>
                      <div
                        v-for="(v,i) in new_open"
                        :key="i"
                      >            
                      <span class="notice_value">{{v}}</span>
                      </div>
                  </v-list>
                </fieldset>
                <fieldset>
                  <legend>Update Service</legend>  
                  <v-list>
                      <div
                        v-for="(v,i) in update"
                        :key="i"
                      >
                      <span class="notice_value">{{v}}</span>
                      </div>
                  </v-list>            
                </fieldset>
                <fieldset>
                  <legend>Comment Service  </legend>  
                  <v-list>
                      <div
                        v-for="(v,i) in comments"
                        :key="i.title"
                      >
                      <span class="comment_value" @click="onLink(v)">{{v.title}}</span>
                      </div>
                  </v-list>            
                </fieldset>
              </div>
            </div>
          </div>
          <div class="checkbox_warpper">
            <input type="checkbox" class="check_box" v-model="checked"> 
            <label class="checkbox_label">Don't watch for 7 days.</label>
          </div>
        </div>
      </v-card-title>
    </v-card>
  </v-dialog>
</template>
<script>

import __C from '../primitives/_constant_'
import { SystemCodeService }from "@/services"
import { mapState } from 'vuex'
import { DBSupportService } from "@/services"

import '@/assets/stylus/ui/component/_jNoticePopup.styl'
export default {
  name: 'j-modal-notice-popup',
  props: {
    value: null
  },
  data: () => ({
    admin: true,
    items: [],
    systemCodeService: null,
    notification: '',
    new_open: [],
    update: [],
    comments: [],
    checked: false,
    sevenDay: false
  }),
  created() {
    this.systemCodeService = new SystemCodeService()
    this.queryLibService = new DBSupportService()
  },
  computed: {
    ...mapState(__C.STORE_NAMESPACE.APP_SERVICE, [
      'updatecomment'
    ]),
    opened: {
      get() { return this.value },
      set(val) { 
        let obj = {
          off : this.value,
          day_off : this.sevenDay
        }
        this.$emit('inputModal', obj) 
      }
    }
  },
  watch: {
    checked(val) {
      if (val) {
        this.sevenDay = true
        this.opened = false
      }
    },
    updatecomment() {
      this.systemCodeService.getCommentList(res => {
        let menu = []
        this.comments = []
        if (res && res.length > 0) {
          setTimeout(() => {
            // this.comments = res
            res.forEach(d => {
              d.comment = d.comment.length > 50 ? d.comment.substr(0,49) : d.comment
              let names = d.phaseName +'  '+d.itemName+ '  '+ `"< ${d.comment} >"`
              let obj = {
                title : names,
                link  : d.url
              }
              menu.push(obj)
            })
            this.comments = [...menu]
          },300)
        }
      })
    }
  },
  methods: {
    onClose() {
      this.opened = false
    },
    onLink(value) {
      if (this.$route.path != value.link) this.$router.push(value.link)
    },
    setnoticeOption(res) {
      if (!res) res = [];
      res.forEach(d => {
        d.use_Notice == 'Y' ? this.items = res : this.items = [] 
      })

      if (this.items.length == 0) return
      this.notification = this.items[0].title
      this.items[0].newOpenService.split(',').forEach(menu => {
        this.new_open.push(menu)
      })     
    },    
  },
  mounted() {
    this.queryLibService.getSqlQueryResult({ 
        idx: 23, 
        name: 'Notice Popup Open',
        filters: ''
      }).then(res => {
        if (res && res.length > 0) {
        this.setnoticeOption(res)
      } else {
        this.items = []
      }
    })

    // this.systemCodeService.getNotification(res => {
    //   if (res && res.length > 0) {
    //     console.log(res)
    //     // this.setnoticeOption(res)
    //   } else {
    //     this.items = []
    //   }
    // })



    // this.systemCodeService.getDataSourceList(res => {
    //   let menu = []
    //   if (res && res.length > 0) {
    //     res.forEach(d => {
    //       let names = 'Datasource : ' +d.title +'  ' +d.revision+'   '+d.week_no
    //       menu.push(names)
    //     })
    //   }
    //   this.update = [...menu]
    // })
    this.queryLibService.getSqlQueryResult({ 
        idx: 22, 
        name: 'Notice Popup DataSource',
        filters: ''
      }).then(res => {
        let menu = []
        if (res && res.length > 0) {
          res.forEach(d => {
            let names = 'Datasource : ' +d.TITLE +'  ' +d.REVISION+'   '+d.WEEK_NO
            menu.push(names)
          })
        }
        this.update = [...menu]
      })
    // this.systemCodeService.getCommentList(res => {
    //   let menu = []
    //   if (res && res.length > 0) {
    //     setTimeout(() => {
    //       // this.comments = res
    //       console.log(res)
    //       res.forEach(d => {
    //         d.comment = d.comment.length > 50 ? d.comment.substr(0,49) : d.comment
    //         let names = d.phaseName +'  '+d.itemName+ '  '+ `"< ${d.comment} >"`
    //         let obj = {
    //           title : names,
    //           link  : d.url
    //         }
    //         menu.push(obj)
    //       })
    //       this.comments = [...menu]
    //     },300)
    //   }
    // })

    
    this.queryLibService.getSqlQueryResult({ 
        idx: 24, 
        name: 'Notice Popup Comment',
        filters: ''
      }).then(res => {
        let menu = []
        if (res && res.length > 0) {
          setTimeout(() => {
            res.forEach(d => {
              d.comment = d.comment.length > 50 ? d.comment.substr(0,49) : d.comment
              let names = d.phaseName +'  '+d.itemName+ '  '+ `"< ${d.comment} >"`
              let obj = {
                title : names,
                link  : d.url
              }
              menu.push(obj)
            })
            this.comments = [...menu]
          },300)
        }
      })
  }

}
</script>