<template>
  <v-toolbar :clipped-left="$vuetify.breakpoint.mdAndUp" class="gnb" flat height="88px">
    <div class="gnb_menu">
      <v-btn class="j_button type03 mr-3" @click="setProc()">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <!-- <v-menu 
        v-for="phase in navDataitems" 
        v-if="visible(phase.phaseCode)"
        offset-y open-on-hover 
        :key="phase.phaseCode"
      > -->
      <v-menu 
        v-for="phase in navDataitems" 
        offset-y open-on-hover 
        :key="phase.phaseCode"
      >
        <!-- Top Level Menu - Menu Phase -->
        <a
          class="main-menu"
          slot="activator"
          :class="{ selected: isSelected(phase.url, $route.path) }"
          @click="phaseRouteTo(phase)"
        >
          <!-- phase.phaseName -->
          <!-- <span style="display: block; line-height: 22px;">{{ $t(`mainmenu.${$tname(phase.phaseCode)}`) }}</span> -->
           <span style="display: block; line-height: 22px;">{{ newItems(phase,'phaseName') }}</span>
        </a>
        <div v-if="phase.children.length > 0" class="menu-item-container">
          <div>
            <!-- Drop Down Menu -->
            <div
              v-for="group in phase.children"
              class="menu-item-group"
              offset-y
              open-on-hover
              :key="group.groupCode"
            >
              <!-- Middle Level Menu - Menu Group -->
              <a
                class="group-menu"
                slot="activator"
                :class="{ selected: isSelected(group.url, $route.path) }"
                @click="groupRouteTo(group)"
              >
                <!-- group.groupName -->
                <!-- <span>{{ $t(`mainmenu.${$tname(group.groupCode)}`) }}</span> -->
                <span>{{ newItems(group,'groupName') }}</span>
              </a>
              <div offset-y open-on-hover class="menu-item-item">
                <!-- Last Level of the Menu - Menu Item -->
                <a
                  v-for="item in group.children"
                  class="item-menu"
                  slot="activator"
                  :class="{ selected: isItemSelected(item, item.url, $route.path), ...styleStatus(item.itemStatus) }"
                  :key="item.itemCode"
                  :title="getItemTitle(item.itemStatus)"
                  @click="itemRouteTo(item)"
                >
                  <!-- item.itemName -->
                  <!-- {{ $t(`mainmenu.${$tname(item.itemCode)}`) }} -->
                  {{ newItems(item,'itemName') }}
                  <img class="router-img" height="14px" style="margin-left: 3px;" :src="item.navIconPath" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </v-menu>
      <div class="lang_select_wrapper">
        <v-select
          v-model="selectedLang"
          dense
          solo
          class="lang_select"
          content-class="lang_select_contents"
          item-text="text"
          item-value="value"
          placeholder="Language"
          :items="langOptions"
          @input="onLanguageChanged"
        ></v-select>
      </div>      
      <!-- <div class="lang_select_wrapper">
        <v-select
          v-model="selectedLang"
          dense
          solo
          class="lang_select"
          content-class="lang_select_contents"
          item-text="text"
          item-value="value"
          placeholder="Language"
          :items="langOptions"
          @input="onLangChanged"
        ></v-select>
      </div> -->
      <!-- <v-select
        v-if="filter.type == 'select'"
        v-model="optionValues[filter.colName]"
        attach
        class="lnb_single_select"
        content-class="lnb_dropdown"
        item-text="name"
        item-value="code"
        :ref="`select_single_${i}`"
        :items="filter.options"
        :label="filter.label"
        :menu-props="{ maxHeight: '190', closeOnContentClick: true}"
        :placeholder="`Select ${filter.label}`"
        @click:append="onClickAppend(`select_single_${i}`)"
        @input="v => sendValuesSelect(filter.colName, v)"
      ></v-select> -->
    </div>
  </v-toolbar>
</template>

<script>
import '@/assets/stylus/ui/component/_gnb.lang.select.styl'

import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapActions } from 'vuex'
import Loading from '@/mixins/loading.mixin'
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-menu-bar',
  mixins: [
    Loading,
    StaticOptions
  ],
  data: () => ({
    selectedLang: 'en',
    prevPhaseUrl: '',
    prevGroupUrl: '',
    prevItemUrl: '',
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['isAdmin']),
    ...mapState(__C.STORE_NAMESPACE.APPLICATION, ['navDataitems']),
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, ['initService', 'prePropagate', 'setItems']),
    ...mapMutations(__C.STORE_NAMESPACE.APPLICATION, ['setNavState', 'setPagecallFunc']),
    ...mapMutations(__C.STORE_NAMESPACE.CONFIG, ['setLanguage']),
    ...mapActions(__C.STORE_NAMESPACE.APP_SERVICE, ['updateFilterStatus']),

    newItems(name,types) {
     if (name[types] == 'Home') return
     return name = this.selectedLang == 'cn' && name.cnName ? name.cnName : name[types]
    },
    
    $tname(name) {
      return name.replace(/-/g, '_')
    },

    onLanguageChanged(lang) {
      console.log(lang)
      this.setLanguage(lang)
    },

    onLangChanged(code) {
      localStorage.setItem('USER_LANG', code)
    },

    phaseRouteTo(phase) {
      if(!phase.urlDirectChild) return
      if(phase.numInservice == 0) return
      if(this.$route.path.includes(phase.urlDirectChild)) return
      this.loading = true
      this.$router.push(phase.urlDirectChild)
    },
    groupRouteTo(group) {
      if(!group.urlDirectChild) return
      if(group.numInservice == 0) return
      if(this.$route.path.includes(group.urlDirectChild)) return
      this.loading = true
      this.$router.push(group.urlDirectChild)
    },
    itemRouteTo(item) {
      if(['PLANNED'].includes(item.itemStatus)) return
      var url_ = null
      if(['UNDER'].includes(item.itemStatus)) url_ = '/UnderConstrucion'
      else {
        // Set Filter-Status for any case of the router's request
        this.updateFilterStatus({ 
          docID: item.idx,
          nonFilter: item.nonFilter 
        })

        this.setItems([])

        if(item.subItem == 'Y') url_ = item.urlDirectChild
        else url_ = item.url

      }
      if(!url_) return
      if(this.$route.path == url_) return      
      
      this.loading = true
      this.$router.push(url_)
    },    
    // itemRouteTo(item) {
    //   if(['PLANNED', 'UNDER'].includes(item.itemStatus)) return
      
    //   // Set Filter-Status for any case of the router's request
    //   this.updateFilterStatus({ 
    //     docID: item.idx,
    //     nonFilter: item.nonFilter 
    //   })

    //   this.setItems([])

    //   if(item.subItem == 'Y') var url_ = item.urlDirectChild
    //   else url_ = item.url

    //   if(!url_) return
    //   if(this.$route.path == url_) return

    //   this.loading = true
    //   this.$router.push(url_)
    // },

    visible(code) {
      return !['HM'].includes(code)
    },
    getItemTitle(status) {
      if(status == 'PLANNED') return 'Planned'
      else if(status == 'UNDER') return 'Under Construction'
      return ''
    },
    setProc() {
      if(this.isAdmin) setTimeout(() => { this.$router.push('/sysenv') })
      else this.$emit('toggle-drawer')
    },
    isSelected(url, path) {
      if(!url) return false
      return path.includes(url)
    },
    isItemSelected(item, url, path) {
      if(!url || !path.includes(url)) return false
      if(url.split('/')[4] != path.split('/')[4]) return false
      return true
    },
    styleStatus(status) {
      if(status == 'PLANNED') return { planned: true }
      else if(status == 'UNDER') return { underconst: true }
      return {}
    },
  }
}
</script>
