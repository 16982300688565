<template>
  <v-dialog hide-overlay content-class="snb_user_info_wrapper" v-model="opened" :width="230">
  <v-card>
  <div class="snb_user_info_inner_wrapper" v-if="account">
    <a 
      class="btn-close" title="find member(s)" 
      @click="onClose"
    >
      <i class="mdi mdi-close"></i>
    </a>

    <div class="snb_user_info" >
      <div class="member-info">
        <div class="member-name">
          <span class="name">{{account.userName}}</span>
        </div>
        <div class="member-position">
          <span class="position">{{account.position}}</span> /
          <span class="department">{{account.office}}</span>
        </div>
      </div>

      <div class="links">
        <a @click="changePassword=true">Change Password</a>
        <div v-if="isSvcAdmin" class="gotoadmin">
         <span class="separator">|</span> 
         <router-link to="/service/admin_tools/account_management/user_monitoring/user_accounts">Go to admin</router-link>
        </div>
      </div>

      <div v-if="changePassword" class="password_inputs">
          <div class="input_wrapper">
          <span>Password</span>
          <input 
            type="password" 
            name="password" 
            v-model="newPassWord"
            :rules="passwordRules"
          />
        </div>
        <div class="input_wrapper">
          <span>Confirm</span>
          <input 
            type="password" 
            name="confirm" 
            v-model="confirm"
            :rules="confirmationRules"
          />
        </div>
        <div class="changed" :class="[messagered ? 'messagered':'messagegreen']">{{changed}}</div>
        <div class="button_wrapper">
          
          <button v-ripple @click="Changes">Change</button>
          <button v-ripple class="cancle" @click="changePassword=false">{{cancle}}</button>
        </div>
      </div>
      
    </div>
    <div class="info-bar"></div>
   </div>
   </v-card>
   </v-dialog>
</template>
<script>
import '@/assets/stylus/ui/component/_jSnbUserInfo.styl'
import __C from '@/primitives/_constant_'
import { mapState, mapMutations } from 'vuex'
import { AdminService } from "@/services"
export default {
  data: () => ({
    messagered:'',
    changePassword: false,
    selectedItem: {},
    adminService: null,
    changed:'',
    cancle:'Cancle',
    confirm: '',
    newPassWord:'',
    passwordRules: [
      v => !!v || 'Required',
      v => (!!v && v.length >= 5) || 'At leat 5 characters required'
    ],
    confirmationRules: [
      v => !!v || 'Required',
      v => (!!v && v.length >= 5) || 'At leat 5 characters required'
    ],
  }),
  props: {
    value: null
  },  
  watch: {
    changePassword(v) {
      if (v == false) {
        this.newPassWord = ''
        this.confirm = ''
        this.changed = ''
        this.cancle ='Cancle'
      }
    },
    newPassWord(v) {
      if (v.length == 0) this.changed = ''
      if (v.length >= 1 && v.length < 5) this.changed ='At leat 5 characters required'
      else if (v.length >=5 ) {
        this.confRule()
      }
    },
    confirm(v) {
      if (v.length == 0) this.changed == ''
      if (v.length >= 1 && v.length < 5) this.changed ='At leat 5 characters required'
      else if (v.length >=5 ) {
        this.confRule()
      }
    }
  },
  created() {
    this.confirmationRules.push(this.confRule)
    this.adminService = new AdminService()
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [
      'setUserInfoOpened',
    ]),  
    onClose() {
      this.setUserInfoOpened(false)
      // this.$emit('closed')
    },
    Changes() {
      this.selectedItem =  { ...this.account }
      this.selectedItem.password = this.newPassWord
      if (this.selectedItem.password == this.confirm && !this.selectedItem.password == '' && !this.confirm == '') {
        this.adminService.updMember(this.selectedItem, res => {
        this.changed ='Updated !'
        this.cancle ='Close'
        this.messagered = false
       })
      }else if(this.selectedItem.password == '' && this.confirm == ''){
        this.changed ='Please Enter Password'
        this.messagered = true
      }else {
        this.changed ='Passwords do not match'
        this.messagered = true
      }
    },
    confRule() {
      if (this.newPassWord === this.confirm){
        this.changed = 'Passwords match'
        this.messagered = false
        }
      else {
        this.changed = 'Passwords do not match'
        this.messagered = true
      }
    }
  },
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),

    isSvcAdmin() { return ['SYS_ADMIN', 'SVC_ADMIN'].includes(this.account.userPermit) },
    opened: {
      get() { return this.value },
      set(val) { this.$emit('inputUser', val) },
    }
  }
}
</script>
