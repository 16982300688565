<template>
  <div class="j_lnb_cms_level_struct">
    <div class="lnb_radio">
      <span>Level Structure</span>
      <div class="option_group">
        <div
          v-for="(option, i) in l1Options"
          :key="i"
        >
          <label :for="`j__lnb_cms_level_radio__l1___${i}`" :class="{ selected: option.LEVEL_CODE1 == LEVEL_CODE1 }">
            <input
              v-model="LEVEL_CODE1"
              type="radio"
              :id="`j__lnb_cms_level_radio__l1___${i}`"
              :name="option.LEVEL_CODE1"
              :value="option.LEVEL_CODE1"
            />
            {{ option.LEVEL_CODE1 }}
          </label>
          <div v-if="option.LEVEL_CODE1 == LEVEL_CODE1" class="option_group child">
            <div
              v-for="(option2, j) in level2Options"
              :key="j"
            >
              <label :for="`j__lnb_cms_level_radio__l2___${j}`" :class="{ selected: option2.LEVEL_CODE2 == LEVEL_CODE2 }">
                <input
                  v-model="LEVEL_CODE2"
                  type="radio"
                  :id="`j__lnb_cms_level_radio__l2___${j}`"
                  :name="option2.LEVEL_CODE2"
                  :value="option2.LEVEL_CODE2"
                />
                {{ option2.LEVEL_CODE2 }}
              </label>
              <div v-if="option2.LEVEL_CODE2 == LEVEL_CODE2 && level3Options.length > 0" class="option_group child">
                <label
                  v-for="(option3, k) in level3Options"
                  :key="k"
                  :for="`j__lnb_cms_level_radio__l3___${k}`"
                >
                  <input
                    v-model="LEVEL_CODE3"
                    type="radio"
                    :id="`j__lnb_cms_level_radio__l3___${k}`"
                    :name="option3.LEVEL_CODE3"
                    :value="option3.LEVEL_CODE3"
                  />
                  {{ option3.LEVEL_CODE3 }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Construction Master Schecule Level Structures
import '@/assets/stylus/ui/component/_jLnbCMSLevelStruct.styl'

import __C from '@/primitives/_constant_'
import { mapState, mapGetters, mapActions } from 'vuex'
import { DBSupportService } from "@/services"
import Loading from '@/mixins/loading.mixin'

export default {
  name: 'j-lnb-c-m-s-level-struct',
  mixins: [
    Loading
  ],
  props: {
    navType: String
  },
  data: () =>({
    // tortueService: null,
    queryLibService: null,

    l1Options: [],
    l2Options: [],
    l3Options: [],
  }),
  computed: {
    ...mapGetters(__C.STORE_NAMESPACE.APP_SERVICE, [
      'filteredValues'
    ]),

    LEVEL_CODE1: {
      get() { return this.filteredValues && this.filteredValues['LEVEL_CODE1'] ? this.filteredValues['LEVEL_CODE1'] : '' },
      set(val) {
        this.loading = true

        let filteredValues___ = { ...this.filteredValues }

        if(filteredValues___['LEVEL_CODE2']) delete filteredValues___['LEVEL_CODE2']
        if(filteredValues___['LEVEL_CODE3']) delete filteredValues___['LEVEL_CODE3']

        this.updateFilteredValues({ ...filteredValues___, LEVEL_CODE1: val }).then(() => {
          this.sendRequest('filtered').then(() => { this.loading = false })
        })
      }
    },
    LEVEL_CODE2: {
      get() { return this.filteredValues && this.filteredValues['LEVEL_CODE2'] ? this.filteredValues['LEVEL_CODE2'] : '' },
      set(val) {
        this.loading = true
        
        let filteredValues___ = { ...this.filteredValues }

        if(filteredValues___['LEVEL_CODE3']) delete filteredValues___['LEVEL_CODE3']

        this.updateFilteredValues({ ...this.filteredValues, LEVEL_CODE2: val }).then(() => {
          this.sendRequest('filtered').then(() => { this.loading = false })
        })
      }
    },
    LEVEL_CODE3: {
      get() { return this.filteredValues && this.filteredValues['LEVEL_CODE3'] ? this.filteredValues['LEVEL_CODE3'] : '' },
      set(val) {
        this.loading = true
        this.updateFilteredValues({ ...this.filteredValues, LEVEL_CODE3: val }).then(() => {
          this.sendRequest('filtered').then(() => { this.loading = false })
        })
      }
    },

    level2Options() { return this.l2Options.filter(o => o.LEVEL_CODE1 == this.LEVEL_CODE1) },
    level3Options() { return this.l3Options.filter(o => o.LEVEL_CODE1 == this.LEVEL_CODE1 && o.LEVEL_CODE2 == this.LEVEL_CODE2) },
  },
  watch: {
    navType(val) {
      if(!val) return
      this.getCMSLevelOptions()
    }
  },
  created() {
    // this.tortueService = new TortueService()
    this.queryLibService = new DBSupportService()
    // this.tortueService.getCMSLevelOptions().then(res => {
    //   this.l1Options = res.l1Options
    //   this.l2Options = res.l2Options
    //   this.l3Options = res.l3Options
    // })
  },
  mounted() {
    this.getCMSLevelOptions()
  },
  methods: {
    ...mapActions(__C.STORE_NAMESPACE.APP_SERVICE, [
      'sendRequest', 
      'updateFilteredValues'
    ]),

    getCMSLevelOptions() {
      if(!this.navType) return

      this.queryLibService.getSqlQueryResult({ 
        idx: 0, 
        name: this.navType == 'topsides' ? 'Filter - Construction Master Schedule Topsides' : 'Filter - Construction Master Schedule Hull',
        filters: ''
      }).then(res => {
        if(!res) return

        let data = res[0]
        this.l1Options = JSON.parse(data.l1Options)
        this.l2Options = JSON.parse(data.l2Options)
        this.l3Options = JSON.parse(data.l3Options)
      })
    },
    sendValuesRadio(level, v) {
      this.loading = true
      this[level] = v

      let optionValues_ = {}
      optionValues_[level] = v

      this.updateFilteredValues({ ...this.filteredValues, ...optionValues_ }).then(() => {
        this.sendRequest('filtered').then(() => { this.loading = false })
      })
    },
  }
}
</script>

<style>

</style>