<template>
  <div>
    <div v-if="chainOptionAvailable" class="__filter_chain__header___">
      <div class="__title__">
        <span>FILTER-CHAIN</span>
        <small v-if="!!represent"><br />{{ represent }}</small>
      </div>
      <i 
        v-if="chainResetable"
        aria-hidden="true" 
        class="v-icon v-icon--link mdi mdi-close theme--light" 
        title="Clear all filters"
        @click="onReset"
      />
    </div>

    <div v-for="(filter, i) in chainOptions" :key="filter.index">
      <!-- Radio Group -->
      <div v-if="filter.type == 'radio'" class="lnb_radio">
        <span>{{ filter.label }}</span>
        <div class="option_group">
          <label
            v-for="(option, i) in filter.options"
            :key="i"
            :for="`j__lnb_filter_chain_type_radio__${filter.colName.toLowerCase()}_${i}`"
          >
            <input
              v-model="optionValues[filter.colName]"
              type="radio"
              :id="`j__lnb_filter_chain_type_radio__${filter.colName.toLowerCase()}_${i}`"
              :name="filter.colName"
              :value="option.code"
              @click="e => { sendValuesRadio(filter.colName, e.target.value) }"
            />
            {{ option.name }}
          </label>
        </div>
      </div>
      <!-- CheckBox Group -->
      <div v-if="filter.type == 'check'" class="lnb_checkbox">
        <span>{{ filter.label }}</span>
        <div class="option_group">
          <label
            v-for="(option, i) in filter.options"
            :key="i"
            :for="`j__lnb_filter_chain_type_check__${filter.colName.toLowerCase()}_${i}`"
          >
            <input
              v-model="optionValues[filter.colName]"
              type="checkbox"
              :id="`j__lnb_filter_chain_type_check__${filter.colName.toLowerCase()}_${i}`"
              :name="`${filter.colName}[]`"
              :value="option"
              @click="e => { sendValuesChecked(filter.colName, e.target.value) }"
            />
            {{ option }}
          </label>
        </div>
      </div>
      <!-- Single Selct -->
      <v-select
        v-if="filter.type == 'select'"
        v-model="optionValues[filter.colName]"
        attach
        class="lnb_single_select"
        content-class="lnb_dropdown"
        item-text="name"
        item-value="code"
        :ref="`select_single_${i}`"
        :items="filter.options"
        :label="filter.label"
        :menu-props="{ maxHeight: '190', closeOnContentClick: true}"
        :placeholder="`Select ${filter.label}`"
        @click:append="onClickAppend(`select_single_${i}`)"
        @input="v => sendValuesSelect(filter.colName, v)"
      ></v-select>
      <!-- Multi-Select -->
      <v-select
        v-if="filter.type == 'mselect'"
        v-model="optionValues[filter.colName]"
        attach multiple clearable
        class="lnb_multi_select"
        content-class="lnb_dropdown lnb_dropdown_multi"
        item-text="name"
        item-value="code"
        :ref="`select_multi_${i}`"
        :items="filter.options"
        :label="filter.label"
        :menu-props="{ maxHeight: '200'}"
        :placeholder="`Select ${filter.label}(s)`"
        @click:append="onClickAppend(`select_multi_${i}`)"
        @input="v => sendValuesSelect(filter.colName, v)"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index === 0">
            <span>{{ item.name }}</span>
          </v-chip>
          <span v-if="index === 1" class="grey--text caption">
            ( + {{ optionValues[filter.colName].length - 1 }} others)
          </span>
        </template>
      </v-select>
    </div>
    <div style="height: 1rem;"></div>
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters, mapActions } from "vuex"
import Loading from '@/mixins/loading.mixin'

export default {
  name: 'j-lnb-filtering-chain',
  mixins: [
    Loading
  ],
  props: {
    chain: {
      type: Array,
      default: () => ([])
    },
    value: null
  },
  data: () => ({
    chainNames: [],
    chainOptions: [],
    interanlChanged: false,
    name: '',
    optionValues: {},
  }),
  computed: {
    ...mapGetters(__C.STORE_NAMESPACE.APP_SERVICE, ['filteredValues']),

    chainOptionAvailable() { return this.chainOptions.length > 0 },
    chainResetable() { 
      if(!this.filteredValues || Object.keys(this.filteredValues).length === 0) return false

      let keys = Object.keys(this.filteredValues)
      let resetable = false

      keys.forEach(k => {
        if(typeof this.filteredValues[k] == 'object') {
          if(Object.keys(this.filteredValues[k]).length > 0) resetable = true

        } else if(!!this.filteredValues[k]) resetable = true
      })

      return resetable
    },

    represent() {
      if(this.chainOptions.length === 0) return ''
      return `REPRESENTED BY ${this.chainOptions[0].label.toUpperCase()}`
    },

  },
  watch: {
    chain: {
      handler(val) {
        this.chainOptions = JSON.parse(JSON.stringify(this.chain))
      },
      deep: true
    },
    chainOptions: {
      handler(val) {
        if(this.interanlChanged) return
        this.onChainOption(val)
      }
    },
  },
  mounted() {
    this.chainOptions = JSON.parse(JSON.stringify(this.chain))
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [
      'setFilteredValues'
    ]),
    ...mapActions(__C.STORE_NAMESPACE.APP_SERVICE, [
      'resetFilterChain',
      'sendRequest',
      'updateFilteredValues'
    ]),

    onClickAppend(target) {
      if (this.$refs[target][0].isMenuActive) {
        this.$refs[target][0].isMenuActive = false;
        this.$refs[target][0].blur()
      } else {
        this.$refs[target][0].isMenuActive = true;
        this.$refs[target][0].focus()
      }
    },
    onChainOption(val) {
      this.chainNames = []
      this.chainOptions.forEach((filter, i) => {
        if(i === 0) this.name = filter.colName
        this.chainNames.push(filter.colName)

        // if (!['check', 'mselect'].includes(filter.type)) {
        //   filter.options.unshift({ code: '', name: `All "${filter.label}"` })
        // }
      })

      let values = {}
      if(Object.keys(this.filteredValues || {}).length > 0) {
        values = JSON.parse(JSON.stringify(this.filteredValues))
        Object.keys(values).forEach(k => {
          if(!this.chainNames.includes(k)) delete values[k]
        })
        this.optionValues = { ...values }

      } else {
        this.chainOptions.forEach(filter => {
          if (['check', 'mselect'].includes(filter.type)) values[filter.colName] = []
          else values[filter.colName] = ''
        })

        // to watch it correctlly, object form should be initialized...
        this.optionValues = { ...values }
      }
    },
    onReset() {
      this.loading = true

      let fValues__ = { ...this.filteredValues }
      this.chainNames.forEach(name => {
        if(fValues__[name]) delete fValues__[name]
      })

      this.updateFilteredValues(fValues__).then(() => {
        this.sendRequest('filtered').then(_ => { 

          let values = {}
          if(Object.keys(this.filteredValues || {}).length > 0) {
            values = JSON.parse(JSON.stringify(this.filteredValues))
            Object.keys(values).forEach(k => {
              if(!this.chainNames.includes(k)) delete values[k]
            })
            this.optionValues = { ...values }

          } else 
            this.optionValues = {}

          this.loading = false 
        })
      })
    },
    sendRequestNResetChain(sender) {
      let senderPos = this.chainNames.indexOf(sender)
      if(senderPos == this.chainNames.length - 1) {
        this.sendRequest('filtered').then(_ => { this.loading = false })
        return
      }

      let parents = this.chainNames.slice(0, senderPos + 1)
      let children = this.chainNames.slice(senderPos + 1)
      let filterNames = { chainName: this.name, targets: children }
      let filteredValues_ = JSON.parse(JSON.stringify(this.filteredValues))

      Object.keys(filteredValues_).forEach(k => {
        if(!parents.includes(k)) delete filteredValues_[k]
      })

      // make children's value empty
      children.forEach(k => {
        if(typeof this.optionValues[k] == 'object') this.optionValues[k] = []
        else this.optionValues[k] = ''
      })

      this.updateFilteredValues({ ...this.filteredValues, ...this.optionValues }).then(() => {
        this.resetFilterChain({
          fNames: JSON.stringify(filterNames),
          fValues: this.trimValues(filteredValues_) ? JSON.stringify(filteredValues_) : ''
        }).then((chainOptions_) => {
          // request result of the chart values & data list
          // it doesn't matter chainOptions were available or not
          this.sendRequest('filtered').then(_ => { this.loading = false })
          this.interanlChanged = true

          chainOptions_.forEach(filter => {
            let chainedFilter = this.chainOptions.find(chained => chained.colName == filter.colName)
            if(chainedFilter) chainedFilter.options = filter.options
          })

          setTimeout(() => { this.interanlChanged = false }, 1000)
        })
      })
    },
    sendValuesDate(k, v) {
      // let optionValues_ = JSON.parse(JSON.stringify(this.optionValues))
      // let index = optionValues_[k].indexOf(v)

      // // if not exist, push it as checked true
      // if(index == -1) optionValues_[k].push(v)
      // // if exist, remove it as checked false
      // else optionValues_[k].splice(index, 1)
      
      // this.updateFilteredValues({ ...this.filteredValues, ...optionValues_ }).then(res => {
      //   this.sendRequest('filtered')
      // })
    },
    sendValuesChecked(k, v) {
      this.loading = true

      let optionValues_ = JSON.parse(JSON.stringify(this.optionValues))
      let index = optionValues_[k].indexOf(v)

      // if not exist, push it as checked true
      if(index == -1) optionValues_[k].push(v)
      // if exist, remove it as checked false
      else optionValues_[k].splice(index, 1)
      
      this.updateFilteredValues({ 
        ...this.filteredValues, 
        ...optionValues_ 
      }).then(res => {
        this.sendRequestNResetChain(k)
      })
    },
    sendValuesRadio(k, v) {
      this.loading = true

      let optionValues_ = JSON.parse(JSON.stringify(this.optionValues))
      this.updateFilteredValues({ 
        ...this.filteredValues, 
        ...optionValues_, 
        ...{ [k]: v } 
      }).then(() => {
        this.sendRequestNResetChain(k)
      })
    },
    sendValuesSelect(k, v) {
      this.loading = true

      // hard clone, if not, this.optionValues is linked with 
      // state prop filteredValues, then will try to mutate
      // filteredValues through 'optionValues'
      // JSON.parse(JSON.stringify(this.optionValues)
      this.updateFilteredValues({ 
        ...this.filteredValues, 
        ...JSON.parse(JSON.stringify(this.optionValues)) 
      }).then(() => {
        this.sendRequestNResetChain(k)
      })
    },
    trimValues(v) {
      if(!v) return false

      Object.keys(v).forEach(k => {
        if(!v[k] || (typeof v[k] == 'object' && Array.isArray(v[k]) && v[k].length === 0)) delete v[k]
      })

      if(Object.keys(v).length === 0) return false

      return true
    },
  }
}
</script>
