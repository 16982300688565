<template>
  <j-overlay 
    v-model="opened" 
    content-right
    class="j_comm_comments"
    style="z-index: 999;"
    :id="localId"
    :opacity="0"
  >
    <div class="j_comm_comments__content_wrapper" :class="classWrapper">
      <div class="lnb">
      </div>
      <div class="content">
        <a v-ripple class="btn-add" title="add new comment" @click="onWrite(0)">
          <i class="mdi mdi-plus-circle-outline"></i>
        </a>
        <a v-ripple class="btn-cancel" title="Cancel & Close" @click="onClose">
          <i class="mdi mdi-close"></i>
        </a>
        <!-- <a v-ripple class="sub-menu---activator" title="sub menu" @click="subMenuOn=!subMenuOn">
          <i class="mdi mdi-dots-vertical"></i>
        </a>
        <div v-if="subMenuOn" class="sub-menu">
          <div 
            v-for="option in subMenuOptions" 
            class="sub-menu-item" 
            :key="option.index"
            @click="onSubMenuClicked(option.mode)"
          >
            <span class="sub-menu-label">{{ option.label }}</span>
          </div>
        </div> -->

        <div>
          <div class="tab-header">
            <span class="tab-title">Comment Service</span>
            <span class="tab-sub-title">{{comments_title}}</span>
          </div>
          <div class="comment_wrapper">

            <div v-for="comment in comments" :class="`comment_item depth_${comment.depth}`" :key="comment.idx">
              <!-- <div class="avatar-wrapper">
                <v-avatar>
                  <img :src="require('@/assets/img/avatar.png')" />
                </v-avatar>
              </div> -->
              <div class="member-info">
                <div class="member-name">
                  <span class="name">{{ comment.userName }}</span>
                  <span v-if="!comment.editor" class="updated">
                    <span class="date">{{ comment.date }}</span>
                    <i class="mdi mdi-clock-outline"></i>
                    <span class="time">{{ comment.time }}</span>
                  </span>
                </div>
                <div class="member-position">
                  <span class="position">{{ comment.office }}</span> /
                  <span class="position">{{ comment.position }}</span> /
                  <span class="department">{{ comment.dept }}</span>
                </div>
                                
                <j-editor 
                  v-if="comment.editor" 
                  :class="`editor depth_${comment.depth}`" 
                  :comment="comment"
                  :progress="progress" 
                  :allowNoComment="true"
                  @save="d => onSave(d, comment.parentId)"
                  @cancel="onCancel" 
                />

                <div v-else>
                  <div class="button_replay_wrapper">
                    <a v-ripple v-if="comment.userId==account.id" class="btn-edit" title="edit" @click="onEdit(comment.idx)">
                      <i class="mdi mdi-pencil"></i>
                    </a>
                    <a v-if="removable(comment)" v-ripple class="btn-remove" title="delete" @click="onDelete(comment)">
                      <i class="mdi mdi-trash-can"></i>
                    </a>
                    <a v-if="comment.depth==0" v-ripple class="btn-reply" title="reply" @click="onWrite(comment.idx)">
                      <i class="mdi mdi-reply"></i>
                    </a>
                  </div>
                  <div v-html="comment.comment" class="comment"></div>
                  <div v-if="comment.files.length > 0" class="attachement">
                    <div v-if="fileDocs(comment.files).length>0" class="file_names">
                      <i class="mdi mdi-attachment"></i>
                      <a v-for="doc in fileDocs(comment.files)" class="names" :key="doc.idx" @click="onDownload(doc.idx)">{{ doc.name }}</a>
                    </div>
                    <div v-if="fileImages(comment.files).length>0" class="thumbs">
                      <div v-for="image in fileImages(comment.files)" class="thumb" :key="image.idx">
                        <a @click="onDownload(image.idx)">
                          <img :src="image.dataUrl" :title="image.name" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :titleDescription="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :buttonText="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>

  </j-overlay>
</template>
<script>
import '@/assets/stylus/ui/component/_jCommComments.styl'

import { mapState, mapGetters, mapMutations } from 'vuex'

import * as d3 from 'd3'
import __C from '@/primitives/_constant_'
import { CommunicationService, FileService } from "@/services"
import { SafeIdMixin } from '@/mixins/safeid.mixin'
import Download from '@/mixins/download.mixin'
import Loading from '@/mixins/loading.mixin'
import Url from '@/services/api/request.url'

export default {
  name: 'j-modal-slide-component---comments',
  mixins: [
    Download,
    Loading,
    SafeIdMixin
  ],
  props: {
    value: null,
  },
  data: () => ({
    communicationService: null,
    fileService: null,

    classWrapper: 'closed',
    comments: [],

    files: [],
    totaluploadprogress: 0,

    opened: false,
    progress: {
      complete: 0,
      total: 0
    },

    subMenuOn: false,
    subMenuOptions: [
      { label: 'One-Way Group Messages', mode: __C.MESSENGER.MODE_1WAY_GROUP_MESSAGE }
    ],

    msgOpen: false,
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Yes", "No", "Cancel"]
    },
    yes: () => {},

    mode: __C.FORM.EDIT_MODE_VIEW,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),
    ...mapState(__C.STORE_NAMESPACE.APP_SERVICE, ['commentProps', 'updatecomment']),
    ...mapGetters(__C.STORE_NAMESPACE.APP_SERVICE, [
      'getCommentTitle',
      'getUpdateComment'
    ]), 
    
    __C_() { return __C },
    comments_title() { return this.getCommentTitle },
    modeView() { return this.mode == __C.FORM.EDIT_MODE_VIEW }
  },
  watch: {
    value(val) {
      if(val) this.onOpen()
      else this.onClose()
    }
  },
  created() {
    this.localId = this.id || 'j-comm-comment__' + this.safeId('')

    this.communicationService = new CommunicationService()
    this.fileService = new FileService()
  },
  mounted() {
    window.addEventListener('keydown', this.onKeydown)
    d3.select(`#${this.localId}`).select('.j-overlay__scrim').on('click', this.onClose)
  },
  beforeDestroy () {
    // Remove event listeners before destroying this page.
    window.removeEventListener('keydown', this.onKeydown)
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [
      'setUpdateComment'
    ]),
    onKeydown(e) {
      if(e.key == 'Escape') this.onClose()
    },
    onOpen() {
      this.getComments()

      this.opened = true
      setTimeout(() => { 
        this.classWrapper = 'opened' 
        d3.select(`#${this.localId}`).select('.content').transition().delay(300).duration(200).style('opacity', 1)
      })
    },
    onClose() {
      d3.select(`#${this.localId}`).select('.content').transition().duration(150).style('opacity', 0)

      setTimeout(() => { 
        this.classWrapper = 'closed'
      }, 150)
      setTimeout(() => { 
        this.opened = false
        this.$emit('input', false) 
      }, 450)
      let flag = this.updatecomment ? false : true
      this.setUpdateComment(flag)
      this.mode = __C.FORM.EDIT_MODE_VIEW
    },


    onCancel(data, parentId) {
      let index_ = this.comments.findIndex(c_ => c_.editor)
      if(index_ < 0) return

      let comments_ = [ ...this.comments ]
      if(this.mode == __C.FORM.EDIT_MODE_EDIT) {
        comments_[index_].editor = false
        comments_[index_].mode = __C.FORM.EDIT_MODE_VIEW
        if (data != undefined)  {
          comments_[index_].comment = data.comment
          this.comments = comments_
    
          let data_ = {
            parentId: parentId,
            refCode : this.commentProps.refCode.toUpperCase(),
            userId  : this.account.id,
            comment : data.comment,
            files   : data.files.map(f => ({
              name      : f.name,
              mimeType  : f.mimeType,
              fileType  : f.fileType,
              ext       : f.ext,
              dataUrl   : f.dataUrl,
              size      : f.size,
              dimention : f.dimention,
            }))
          }

          let params = new FormData()
          params.append("idx", comments_[index_].idx)
          params.append("data", JSON.stringify(data_))
          
          data.files.forEach(f => { params.append("files", f) })
          
          let config = {
            onUploadProgress: e => {
              this.progress = {
                complete: e.loaded,
                total: e.total
              }
            }
          }

          this.communicationService.editComment(params, config).then(() => {
            this.progress = { complete: 0, total: 0 }
            this.getComments()
            this.mode = __C.FORM.EDIT_MODE_VIEW
            let flag = this.updatecomment ? false : true
            this.setUpdateComment(flag)
            // this.loading = false
          })
        }else {
          this.getComments()
          this.mode = __C.FORM.EDIT_MODE_VIEW
        }

      } else {
        comments_[index_] = null
        this.comments = comments_.filter(c_ => !!c_)
      }

      this.mode = __C.FORM.EDIT_MODE_VIEW

      this.$emit('updateComment', {code: this.commentProps.refCode, comments: comments_})
    },
    // onDownload(fileId, name, type) {
    onDownload(fileId) {
      var iframe = document.createElement('iframe')
      document.body.appendChild(iframe)
      let params = new URLSearchParams()
      params.append('token', this.account.token)
      iframe.src = `${Url.download}/${fileId}?${params.toString()}`

      // setTimeout(() => {
      //   iframe.parentNode.removeChild(iframe)
      // }, 10000);

      // let config = {
      //   responseType: 'blob',
      //   onDownloadProgress: e => {
      //     console.log(e.loaded, e.total)
      //   }
      // }
      // this.fileService.download(fileId, config).then(file => {
      //   let blob_ = new Blob([file], { type: type})
      //   let len_ = blob_.length
      //   let buffer_ = new ArrayBuffer(len_)
      //   let view_ = new Uint8Array(buffer_)

      //   for (var i = 0; i < len_; i++) {
      //     view_[i] = blob_.charCodeAt(i);
      //   }

      //   let url = URL.createObjectURL(blob_);
      //   let link = document.createElement('a')
      //   link.href = url
      //   link.setAttribute('download', name)
      //   document.body.appendChild(link)
      //   link.click()
      // })
    },
    onDelete(comment) {
      this.yes = () => {
        this.msgOpen = false
        this.communicationService.delComment(comment.idx).then(() => {
          let index_ = this.comments.findIndex(c_ => c_.idx == comment.idx)
          let comments_ = [ ...this.comments ]
          comments_[index_] = null
          this.comments = comments_.filter(c_ => !!c_)
          this.$emit('updateComment', {code: this.commentProps.refCode, comments: comment})
        })
      let flag = this.updatecomment ? false : true
      this.setUpdateComment(flag)
        this.yes = () => {}
      }

      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Action Approval"

      if(comment.files.length > 0) {
        this.msgInfo.titleDescription = "Important Notification"
        this.msgInfo.message = `
          <span style="
            font-style: italic; 
            font-weight: 300; 
            font-size: 12px; 
            color: #f75c02
          ">
            Attention: All of its attachments will be deleted.
          </span><br /><br />
          Do you want to delete this comment?
        `
      } else this.msgInfo.message = "Do you want to delete this comment?"

      this.msgInfo.button = [true, false, true]
      this.msgInfo.buttonText = ['Yes', 'No', 'Cancel']
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true

      // this.$emit('updateComment', {code: this.commentProps.refCode, comments: comment})
    },
    onEdit(idx) {
      if(this.mode != __C.FORM.EDIT_MODE_VIEW) return

      let comments_ = [ ...this.comments ]
      let index_ = this.comments.findIndex(c_ => c_.idx == idx)
      comments_[index_].editor = true
      comments_[index_].mode = __C.FORM.EDIT_MODE_EDIT

      this.comments = comments_
      this.mode = __C.FORM.EDIT_MODE_EDIT

      this.$emit('updateComment', {code: this.commentProps.refCode, comments: comments_})
    },
    onSave(data, parentId) {
      // this.loading = true
      if(this.mode == __C.FORM.EDIT_MODE_EDIT) {
        this.onCancel(data, parentId)
        return
      }

      let data_ = {
        parentId: parentId,
        refCode : this.commentProps.refCode.toUpperCase(),
        userId  : this.account.id,
        comment : data.comment,
        files   : data.files.map(f => ({
          name      : f.name,
          mimeType  : f.mimeType,
          fileType  : f.fileType,
          ext       : f.ext,
          dataUrl   : f.dataUrl,
          size      : f.size,
          dimention : f.dimention,
        }))
      }

      let params = new FormData()
      params.append("data", JSON.stringify(data_))
      
      data.files.forEach(f => { params.append("files", f) })

      let config = {
        onUploadProgress: e => {
          this.progress = {
            complete: e.loaded,
            total: e.total
          }
        }
      }
      this.communicationService.putComment(params, config).then(() => {
        this.progress = { complete: 0, total: 0 }
        this.getComments()
        this.mode = __C.FORM.EDIT_MODE_VIEW
        let flag = this.updatecomment ? false : true
        this.setUpdateComment(flag)
        // this.loading = false
      })

      this.$emit('updateComment', {code: this.commentProps.refCode, comments: data.comment})
    },
    onThumbnail(file, dataUrl) {
      this.$emit('thumbnail', { file, dataUrl })
    },
    onWrite(parentId) {
      if(this.mode != __C.FORM.EDIT_MODE_VIEW) return

      let comment_ = {
        editor: true,
        depth: parentId ? 1 : 0,
        parentId: Number(parentId),
        userName: this.account.userName,
        position: this.account.position,
        dept: this.account.department,
      }
      if(parentId) {
        let comments_ = [ ...this.comments ]
        let index_ = this.comments.findIndex(c_ => c_.idx == parentId)
        let subComments_ = this.comments.filter(c_ => c_.idx == parentId || c_.parentId == parentId)

        subComments_.push(comment_)
        comments_.splice(index_, subComments_.length-1, ...subComments_)
        comments_ = comments_.filter(c_ => !!c_)

        this.comments = comments_
        
      } else {
        this.comments.push(comment_)
      }
      
      this.mode = __C.FORM.EDIT_MODE_ADD
    },

    
    fileDocs(files) {
      return files.filter(f_ => !['image', 'video'].includes(f_.fileType))
    },
    fileImages(files) {
      return files.filter(f_ => ['image', 'video'].includes(f_.fileType))
    },
    getComments() {
      this.communicationService.getComments(this.commentProps.refCode.toUpperCase()).then(res => {
        this.comments = res
      })
    },
    removable(comment) {
      if(comment.userId != this.account.id) return false
      if(comment.depth == 0 && this.comments.findIndex(c_ => c_.parentId == comment.idx) >= 0) return false
      return true
    }
  }
}
</script>
