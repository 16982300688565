<template>
  <v-footer inset class="fnb" height="99px">
    <div class="fnb__inner">
      <v-toolbar-side-icon @click.stop="$emit('toggle-drawer')">
        <v-icon>mdi-menu</v-icon>
      </v-toolbar-side-icon>
      <div class="fnb_detail">
        <div class="fnb_detail__copyright">
          <span>JGS, Inc. 2017</span>
          <small>Project Management Service</small>
        </div>
        <ul class="fnb_detail__menu">
          <li v-for="item in Fnb" :key="item.index">
            <a title="Under Construction">{{ item.title }}</a>
          </li>
        </ul>
      </div>
    </div>
  </v-footer>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  data: () => ({
    Fnb: [
      // {
      //   title: "PROJECT DATA",
      //   href: "/lll"
      // },
      // {
      //   title: "SERVICE PERMISSION",
      //   href: ""
      // },
      // {
      //   title: "USER PERMISSION",
      //   href: ""
      // },
      // {
      //   title: "PROJECT DATA",
      //   href: ""
      // },
      // {
      //   title: "ERROR REPORT",
      //   href: ""
      // }
    ]
  }),
  computed: {
    ...mapState('config', ['layout'])
  },
  methods: {
    ...mapMutations('config', ['setLnbVisible']),

    configLayout() {
      return this.layout
    }
  }
}
</script>