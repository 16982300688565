<template>
  <div>
    <div v-if="headerVisible" class="__filter_chain__header___">
      <div class="__title__">
        <span>SINGLE FILTER</span>
        <small><br />FILTER-OPTIONS NOT CHAINED</small>
      </div>
      <i 
        v-if="resetable"
        aria-hidden="true" 
        class="v-icon v-icon--link mdi mdi-close theme--light" 
        title="Clear all filters"
        @click="onReset"
      />
    </div>

    <div v-for="filter in filterOptionsLocal" :key="filter.index">
      <!-- Radio Group -->
      <div v-if="filter.type == __C_.FILTER.TYPE_CODE_RADIO" class="lnb_radio">
        <span>{{ filter.label }}</span>
        <div class="option_group">
          <label
            v-for="(option, i) in filter.options"
            :key="i"
            :for="`j__lnb_filter_type_radio__${filter.colName.toLowerCase()}_${i}`"
          >
            <input
              v-model="optionValues[filter.colName]"
              type="radio"
              :id="`j__lnb_filter_type_radio__${filter.colName.toLowerCase()}_${i}`"
              :name="filter.colName"
              :value="option.code"
              @click="e => { sendValuesRadio({ [filter.colName]: e.target.value }) }"
            />
            {{ option.name }}
          </label>
        </div>
      </div>
      <!-- CheckBox Group -->
      <div v-else-if="filter.type == __C_.FILTER.TYPE_CODE_CHECK" class="lnb_checkbox">
        <span>{{ filter.label }}</span>
        <div class="option_group">
          <label
            v-for="(option, i) in filter.options"
            :key="i"
            :for="`j__lnb_filter_type_check__${filter.colName.toLowerCase()}_${i}`"
          >
            <input
              v-model="optionValues[filter.colName]"
              type="checkbox"
              :id="`j__lnb_filter_type_check__${filter.colName.toLowerCase()}_${i}`"
              :name="`${filter.colName}[]`"
              :value="option"
              @click="e => { sendValuesChecked(filter.colName, e.target.value) }"
            />
            {{ option }}
          </label>
        </div>
      </div>
      <!-- Single Selct -->
      <v-select
        v-else-if="filter.type == __C_.FILTER.TYPE_CODE_SELECT"
        v-model="optionValues[filter.colName]"
        attach
        class="lnb_single_select"
        content-class="lnb_dropdown"
        item-text="name"
        item-value="code"
        :items="filter.options"
        :label="filter.label"
        :menu-props="{ maxHeight: '190', closeOnContentClick: true}"
        :placeholder="`Select ${filter.label}`"
        @input="sendValuesSelect"
      ></v-select>
      <!-- Multi-Select -->
      <v-select
        v-else-if="filter.type == __C_.FILTER.TYPE_CODE_SELECT_MULTI"
        v-model="optionValues[filter.colName]"
        attach
        multiple
        class="lnb_multi_select"
        content-class="lnb_dropdown lnb_dropdown_multi"
        item-text="name"
        item-value="code"
        :items="filter.options"
        :label="filter.label"
        :menu-props="{ maxHeight: '200'}"
        :placeholder="`Select ${filter.label}(s)`"
        @input="sendValuesSelect"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index === 0">
            <span>{{ item.name }}</span>
          </v-chip>
          <span v-if="index === 1" class="grey--text caption">
            ( + {{ optionValues[filter.colName].length - 1 }} others)
          </span>
        </template>
      </v-select>
      <!-- Nav || Progress Nav -->
      <v-list 
        v-else-if="filter.type == __C_.FILTER.TYPE_CODE_NAV || filter.type == __C_.FILTER.TYPE_CODE_NAV_PROGRESS" 
        class="lnb_list svg_controls"
      >
        <v-list-tile v-for="(option, i) in filter.options" :class="classNavSelected(filter.colName, option.code, i)"  :key="option.index">
          <v-list-tile-content>
            <v-list-tile-title @click="() => { sendValuesNav(filter.colName, option.code, option.refs) }">
              <div>{{ option.name }}</div>
              <div v-if="filter.type == __C_.FILTER.TYPE_CODE_NAV_PROGRESS" class="bar_wrapper" >
                <div
                  class="lnb_bar"
                  ref="lnb_bar"
                  :style="`width: ${option.value}%;`"
                >
                <span v-if="progNavAvailable" v-html="`${option.value}%`" 
                :class="{'right_position': compared[i]['rightSide'], 'apply_left_padding': compared[i]['leftAlign']}" 
                :style="`--bar-width: ${compared[i]['barSize'] + 3}px;`"
                />
                </div>
              </div>
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
      <!-- Custom Component -->
      <component
        v-else-if="filter.type == __C_.FILTER.TYPE_CODE_CUSTOM"
        :is="filter.component"
        :filter-option="filter"
      />
    </div>
    <div style="height: 1rem;"></div>
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapGetters, mapActions } from 'vuex'
import JLnbCustomFilters from '@/components/JLnbCustomFilters'
import Loading from '@/mixins/loading.mixin'

export default {
  name: 'j-lnb-filtering-options',
  mixins: [
    Loading
  ],
  components: {
    ...JLnbCustomFilters
  },
  props: {
    value: null
  },
  data: () => ({
    compared: [],
    filterNames: [],
    filterOptionsLocal: [],
    optionValues: {},
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.APP_SERVICE, ['codePropagated']),
    ...mapGetters(__C.STORE_NAMESPACE.APP_SERVICE, [
      'filteredValues',
      'filterOptions'
    ]),

    __C_() { return __C },

    progNavAvailable() {
      return this.compared.length > 0 
    },
    headerVisible() {
      let visible = true
      if(this.filterOptionsLocal.find(f => [__C.FILTER.TYPE_CODE_NAV, __C.FILTER.TYPE_CODE_CUSTOM].includes(f.type))) visible = false

      return visible
    },
  },
  watch: {
    filterOptions: {
      handler(val) {
        if(!val) return
        this.setLocalEnv()
      },
      deep: true
    },
  },
  created() {
    this.setLocalEnv()
  },
  methods: {
    ...mapActions(__C.STORE_NAMESPACE.APP_SERVICE, [
      'sendRequest', 
      'updateFilteredValues'
    ]),

    onReset() {
      this.loading = true
      
      let fValues__ = { ...this.filteredValues }
      this.filterOptionsLocal.forEach(f => {
        if(fValues__[f.colName]) delete fValues__[f.colName]
      })

      this.updateFilteredValues(fValues__).then(() => {
        this.sendRequest('filtered').then(_ => { 

          let values = {}
          if(Object.keys(this.filteredValues || {}).length > 0) {
            values = JSON.parse(JSON.stringify(this.filteredValues))
            Object.keys(values).forEach(k => {
              if(!this.filterNames.includes(k)) delete values[k]
            })
            this.optionValues = { ...values }

          } else
            this.optionValues = {}

          this.loading = false 
        })
      })
    },

    classNavSelected(colName, code, i) {
      if(!this.optionValues[colName]) {
        if(i===0) return 'selected'
        return ''
      }
      return this.optionValues[colName] == code ? 'selected' : ''
    },

    resetable() { 
      if(!this.filteredValues || Object.keys(this.filteredValues).length === 0) return false

      let keys = Object.keys(this.filteredValues)
      let resetable = false

      keys.forEach(k => {
        if(typeof this.filteredValues[k] == 'object') {
          if(Object.keys(this.filteredValues[k]).length > 0) resetable = true

        } else if(!!this.filteredValues[k]) resetable = true
      })

      return resetable
    },
    setLocalEnv() {
      this.filterOptionsLocal = JSON.parse(JSON.stringify(this.filterOptions || []))

      let filterOptions_ = this.filterOptionsLocal
      if(!filterOptions_ || Object.keys(filterOptions_).length === 0) return
      
      this.filterNames = []
      filterOptions_.forEach((filter, i) => {
        if(i === 0) this.name = filter.colName
        this.filterNames.push(filter.colName)

        // if (['custom'].includes(filter.type)) return
        // else if ('nav' == filter.type) {
        //   if(filter.options.length > 1) filter.options.unshift({ code: '', name: `All "${filter.label}"` })
        // }
        // else if (!['check', 'mselect'].includes(filter.type)) {
        //   filter.options.unshift({ code: '', name: `All "${filter.label}"` })
        // }
      })

      let values = {}
      if(Object.keys(this.filteredValues || {}).length > 0) {
        values = JSON.parse(JSON.stringify(this.filteredValues))
        Object.keys(values).forEach(k => {
          if(!this.filterNames.includes(k)) delete values[k]
        })
        this.optionValues = { ...values }

      } else {
        filterOptions_.forEach(filter => {
          if (['custom'].includes(filter.type)) return
          else if (['check', 'mselect'].includes(filter.type)) values[filter.colName] = []
          else values[filter.colName] = ''
        })

        // to watch it correctlly, object form should be initialized...
        this.optionValues = { ...values }
      }

      let prognav_ = filterOptions_.findIndex(f => f.type == __C.FILTER.TYPE_CODE_NAV_PROGRESS)
      if(prognav_ >= 0) setTimeout(() => { this.compareforTextAlign(filterOptions_[prognav_].options) }, 250)
    },

    compareforTextAlign(filter_) {
      let specificValue = 15
      let leftPadStandard = 20

      filter_.forEach((v, i) => {
        let underSpecificBool
        let leftPadBool
        let barSize = this.$refs.lnb_bar[i] ? this.$refs.lnb_bar[i].getBoundingClientRect().width : 0

        underSpecificBool = v.prog <= specificValue
        leftPadBool = specificValue <= v.prog  && v.prog <= leftPadStandard
        this.compared.push({'rightSide': underSpecificBool, 'barSize': barSize, 'leftAlign': leftPadBool })
      })
    },

    sendValuesChecked(k, v) {
      this.loading = true

      let optionValues_ = JSON.parse(JSON.stringify(this.optionValues))
      let index = optionValues_[k].indexOf(v)

      // if not exist, push it as checked true
      if(index == -1) optionValues_[k].push(v)
      // if exist, remove it as checked false
      else optionValues_[k].splice(index, 1)
      
      this.updateFilteredValues({ ...this.filteredValues, ...optionValues_ }).then(() => {
        this.sendRequest('filtered').then(() => { this.loading = false })
      })
    },
    sendValuesRadio(v) {
      this.loading = true

      let optionValues_ = JSON.parse(JSON.stringify(this.optionValues))
      this.updateFilteredValues({ ...this.filteredValues, ...optionValues_, ...v }).then(() => {
        this.sendRequest('filtered').then(() => { this.loading = false })
      })
    },
    sendValuesSelect(v) {
      this.loading = true

      // hard clone, if not, this.optionValues is linked with 
      // state prop filteredValues, then will try to mutate
      // filteredValues through 'optionValues'
      // JSON.parse(JSON.stringify(this.optionValues)
      
      this.updateFilteredValues({ ...this.filteredValues, ...JSON.parse(JSON.stringify(this.optionValues)) }).then(res => {
        this.sendRequest('filtered').then(() => { this.loading = false })
      })
    },
    sendValuesNav(k, v, refs) {
      // Temproal process to prevent navigating. ---------------------
      if(!this.codePropagated.id || this.codePropagated.id < 1) return

      this.loading = true
      
      if(refs) this.optionValues = { ...this.optionValues, [k]: v, ...refs }
      else this.optionValues[k] = v
      
      this.updateFilteredValues(JSON.parse(JSON.stringify(this.optionValues))).then(() => {
        this.sendRequest('filtered').then(() => { this.loading = false })
      })
    }
  }
}
</script>
