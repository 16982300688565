<template>
  <div>
    <div v-if="reportCatOptions[catcode].length > 0" class="lnb_radio">
      <span>Category</span>
      <div class="option_group">
        <label
          v-for="option in groupOptions"
          :key="option.value"
          :for="`j__lnb_filter_type_radio__${option.value.toLowerCase()}`"
        >
          <input
            v-model="groupcode"
            type="radio"
            :id="`j__lnb_filter_type_radio__${option.value.toLowerCase()}`"
            :name="option.value"
            :value="option.value"
            @click="sendValuesRadio"
          />
          {{ option.text }}
        </label>
      </div>
    </div>
    <div v-if="recentDownloads.length > 0" class="lnb_report_list">
      <span>Recent</span>
      <v-list class="option_list">
        <v-list-tile 
          v-for="report in recentDownloads" 
          :key="report.idx"
        >
          <v-list-tile-content :class="classRecentSelected(report.idx)"  >
            <v-list-tile-title @click="onDownload(report)">
              <div class="option_name w140" :title="report.name">{{ report.name }}</div>
              <div class="sub_name">{{ getRecentDays(report.date) }}</div>
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </div>
  </div>
</template>

<script>
import '@/assets/stylus/ui/component/_j.lnb.report.category_.styl'

import __C from '@/primitives/_constant_'
import __M from 'moment'
import { mapState, mapMutations, mapActions } from 'vuex'
import StaticOptions from '@/mixins/staticOptions'
import FileServiceMixin from '@/mixins/file.service.mixin'

export default {
  name: 'j-lnb-report-category',
  mixins: [
    FileServiceMixin,
    StaticOptions
  ],
  props: {
    value: null
  },
  data: () => ({
    catcode: '',
    recentSelected: 0
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),
    ...mapState(__C.STORE_NAMESPACE.APP_SERVICE, ['recentDownloads']),
    ...mapState(__C.STORE_NAMESPACE.APPLICATION, ['reportCategory']),

    __C_() { return __C },

    groupcode: {
      get() { return this.reportCategory },
      set(val) { this.setReportCategory(val) }
    },
    groupOptions() {
      let options_ = this.reportCatOptions[this.catcode]
      if(options_.length === 0) return

      return [ { text: 'All', value: 'ALL' }, ...options_]
    }
  },
  watch: {
    $route() {
      this.setCatCode()
    },
  },
  created() {
    this.setCatCode()
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.APPLICATION, [
      'setReportCategory'
    ]),
    ...mapActions(__C.STORE_NAMESPACE.APPLICATION, [
      'getBoardReportValues', 
      'updateBoardReportValues'
    ]),

    onDownload(report) {
      this.recentSelected = report.idx
      this.downloadfile(report.idx, this.account.token)
    },
    classRecentSelected(idx) {
      return this.recentSelected == idx ? 'selected' : ''
    },
    getRecentDays(date) {
      let days = __M().diff(__M(date).toDate(), 'days')
      if(days == 1) return `${days} day`
      return `${days} days`
    },
    sendValuesRadio(e) {
      let value = e.target.value

      this.getBoardReportValues(this.catcode).then(values => {
        values.groupcode = value

        this.updateBoardReportValues({ 
          id: this.catcode,
          values: values
        })
      })
    },
    setCatCode() {
      if(this.$route.name == __C.BOARD.REPORT_CAT_NAME_WEEKLY) {
        this.catcode = __C.BOARD.REPORT_CAT_CODE_WEEKLY
        return
      } 
      else if(this.$route.name ==  __C.BOARD.REPORT_CAT_NAME_FABRICATOR) this.catcode = __C.BOARD.REPORT_CAT_CODE_FABRICATOR
      else if(this.$route.name ==  __C.BOARD.REPORT_CAT_NAME_JGS) this.catcode = __C.BOARD.REPORT_CAT_CODE_JGS
      else this.catcode = __C.BOARD.REPORT_CAT_CODE_SPECIAL

      this.getBoardReportValues(this.catcode).then(res => {
        this.groupcode = res.groupcode || 'ALL'
      })
    },
  }
}
</script>
