<template>
  <div class="content">
    <div class="__filter_chain__header___">
      <div class="__titleS">
        <span>FILTER-CHAIN</span>
      </div>
      <i 
        v-if="chainResetable"
        aria-hidden="true" 
        class="v-icon v-icon--link mdi mdi-close theme--light" 
        title="Clear all filters"
        @click="Clear"
      />
    </div>
    <div class="__filter_chain__header___">
      <v-select
        v-model="selectedItem.lv1"
        attach
        class="lnb_multi_select"
        content-class="lnb_dropdown lnb_dropdown_multi"
        label="Level 1"
        item-text="lv1"
        item-value="lv1"
        placeholder="Select a LV1"
        :menu-props="{contentClass:'single_select'}"
        :items="fotions.lv1"
        @input="() => { onFilterChanged('lv1') }"
      ></v-select>     
    </div>
    <div class="__filter_chain__header___">
      <v-select
        v-model="selectedItem.lv2"
        attach
        class="lnb_multi_select"
        content-class="lnb_dropdown lnb_dropdown_multi"
        label="Level 2"
        item-text="lv2"
        item-value="lv2"
        placeholder="Select a LV2"
        :menu-props="{contentClass:'single_select'}"
        :items="fotions.lv2"
        @input="() => { onFilterChanged('lv2') }"
      ></v-select>       
    </div>
    <div class="__filter_chain__header___">
      <v-select
        v-model="selectedItem.lv3"
        attach
        class="lnb_multi_select"
        content-class="lnb_dropdown lnb_dropdown_multi"
        label="Level 3"
        item-text="lv3"
        item-value="lv3"
        placeholder="Select a LV3"
        :menu-props="{contentClass:'single_select'}"
        :items="fotions.lv3"
        @input="() => { onFilterChanged('lv3') }"
      ></v-select>       
    </div>
    <div class="__filter_chain__header___">
      <v-select
        v-model="selectedItem.area"
        attach
        class="lnb_multi_select"
        content-class="lnb_dropdown lnb_dropdown_multi"
        label="Area"
        item-text="area"
        item-value="area"
        placeholder="Select a Area"
        :menu-props="{contentClass:'single_select'}"
        :items="fotions.area"
        @input="() => { onFilterChanged('area') }"
      ></v-select>       
    </div>
    <div class="__filter_chain__header___">
      <v-select
        v-model="selectedItem.block"
        attach 
        class="lnb_multi_select"
        content-class="lnb_dropdown lnb_dropdown_multi"
        label="Block No."
        item-text="block"
        item-value="block"
        placeholder="Select Block No."
        :menu-props="{contentClass:'single_select'}"
        :items="fotions.block"
        @input="() => { onFilterChanged('block') }"
      ></v-select>       
    </div>
    <div class="clear_button">
      <!-- <j-button class="type01 clear type_full"  @click="Clear">Clear</j-button>  -->
    </div>               
  </div>
</template>

<script>
import '@/assets/stylus/ui/component/_j.lnb.report.category_.styl'

import _ from 'lodash'
import __C from '@/primitives/_constant_'
import __M from 'moment'
import { mapState,mapGetters, mapMutations, mapActions } from 'vuex'
import StaticOptions from '@/mixins/staticOptions'
import FileServiceMixin from '@/mixins/file.service.mixin'
import { FileService, FetchStreamService } from "@/services"
export default {
  name: 'j-lnb-report-category',
  mixins: [
    FileServiceMixin,
    StaticOptions
  ],
  props: {
    value: null
  },
  data: () => ({
    catcode: '',
    recentSelected: 0,
    fsources: {},
    fotions: {},
    fnames: ['lv1', 'lv2', 'lv3', 'area', 'block'],
    selectedItem: {},
    fileService: null,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),
    ...mapGetters(__C.STORE_NAMESPACE.APP_SERVICE, ['getEqStatusUp']),
    __C_() { return __C },
    chainResetable() {
      if (Object.keys(this.selectedItem).length > 0) return true
      else return false
    }
  },
  created() {
    this.fileService = new FileService()
  },
  mounted() {
    this.fileService.getEqFilterOptionsList().then(res => {
      Object.keys(res).forEach(k => {
        this.fsources[k] = [...res[k]]
        this.fotions[k] = [...res[k]]
      })
      this.fotions = { ...this.fotions }
    })
  },
  watch: {
    getEqStatusUp(v) {
      if (v) {
        this.selectedItem = {}
        this.selectedItem = { ...this.selectedItem }
        this.EqPhotoStatusSet(false)
        this.fileService.getEqFilterOptionsList().then(res => {
          Object.keys(res).forEach(k => {
            this.fsources[k] = [...res[k]]
            this.fotions[k] = [...res[k]]
          })
        })

        this.setItem()
      }
    }
  },
  methods: {
    ...mapActions(__C.STORE_NAMESPACE.APP_SERVICE, [
      'EqPhotoItemSet',
      'EqPhotoStatusSet',
      'EqPhotoClearSet'
    ]), 
    setItem() {
      let res = this.selectedItem
      this.EqPhotoItemSet(_.cloneDeep(res))
    },
    Clear() {
      this.selectedItem = {}
      this.selectedItem = { ...this.selectedItem }
      this.fotions = { ... this.fsources}
      this.setItem()
      this.EqPhotoClearSet(true)
    },

    onFilterChanged(name) {
      this.EqPhotoClearSet(false)
    
      let idx = this.fnames.findIndex(n => n == name)
      
      let parentnames = this.fnames.slice(0, idx+1)
      let childnames = this.fnames.slice(idx+1)
      
      let flagAll = false
      if(idx+1 < this.fnames.length) {
        childnames.forEach(name__ => {
          if (this.fotions[name__] != undefined ) {
            this.fotions[name__] = this.fsources[name__].filter(s => {
              let wheresource = ''
              let whereselect = ''

              parentnames.forEach(n__ => {
                if(this.selectedItem[n__]) {
                  if(this.selectedItem[n__] == 'ALL') {
                    // skip ---
                  } else {
                    wheresource += `${s[n__]}`
                    whereselect += `${this.selectedItem[n__]}`
                  }
                }
              })
              
              this.selectedItem[name__] = null
              this.selectedItem = { ...this.selectedItem }
                
              return s[name__] == 'ALL' || wheresource == whereselect
            })
          }
        })

      }
      this.setItem()
    },
  }
}
</script>
<style lang="stylus" scoped>
  .__titleS
    margin-left : 1.4rem;
    line-height : 1.1rem;
    color :#fff;
    font-size: 1rem;
    font-font-weight :600;
</style>
